<template>
  <div class="set-point">
    <!-- 测点信息 -->
    <el-form :model="form1" :inline="true">
      <div class="formMain">
        <div class="formMain-title">
          <img style="margin-right: 12px" src="@/assets/images/fragment-title-icon.png" />
          <div>测点信息</div>
        </div>
        <div class="formMain-content box center flexW">
          <el-form-item label="测点编号" prop="code">
            <el-input v-model="form1.code" disabled />
          </el-form-item>

          <el-form-item label="位置描述" class="long-input" prop="site">
            <el-input v-model="form1.site" disabled />
          </el-form-item>

          <el-form-item label="监测类型" prop="typeGroupId">
            <el-input v-model="form1.typeGroupName" disabled />
          </el-form-item>

          <el-form-item label="监测指标" prop="typeId">
            <el-input v-model="form1.typeName" disabled />
          </el-form-item>

          <el-form-item label="方向选择" prop="typeRel1" v-if="form1.typeId == '1008'">
            <el-checkbox-group v-model="form1.typeRel1">
              <el-checkbox v-for="(item, index) in xyzList" :label="item.id" :key="index" disabled>{{ item.name }} </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="采样时间间隔" class="long-input" prop="frequency"> <el-input v-model="form1.frequency" disabled /> </el-form-item>

          <!-- 新添加 测点名称 -->
          <el-form-item label="测点名称" class="long-input" prop="serialNumber">
            <el-input v-model.number="form1.name" placeholder="请输入" disabled />
          </el-form-item>
          <!-- 所属系统 -->
          <el-form-item label="所属系统" class="long-input" prop="systemId">
            <el-input v-model="form1.systemName" disabled></el-input>
            <!-- <el-select v-model="form1.systemId" placeholder="请选择" @change="selectSys">
              <el-option
                  v-for="item in sysList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  disabled
                  />
            </el-select> -->
          </el-form-item>
          <el-form-item label="监测内容" class="long-input" prop="contentName">
            <!-- <el-select v-model="form1.contentId" placeholder="请选择">
          <el-option v-for="item in contentList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select> -->
            <el-input v-model="form1.contentName" placeholder="请输入" disabled />
          </el-form-item>

          <!-- 健康评估系数 -->
          <el-form-item label="状况评估系数" class="long-input" prop="health_coefficient" v-if="form1.healthAssess == '1'">
            <el-input v-model="form1.healthCoefficient" placeholder="请输入" disabled />
          </el-form-item>

          <!-- <el-form-item label="数据类型" prop="staticDynamicSelected">
            <el-input v-model="dataTypeOpitons[form1.staticDynamicSelected - 1]" disabled />
          </el-form-item> -->

          <el-form-item label="传感器型号" prop="model">
            <el-input v-model="form1.model" disabled />
          </el-form-item>
          <el-form-item label="精度" prop="precisions">
            <el-input v-model="form1.precisions" disabled />
          </el-form-item>
          <el-form-item label="系数" prop="coefficient">
            <el-input v-model="form1.coefficient" disabled />
          </el-form-item>
          <!-- <el-form-item label="单位" prop="unit">
        <el-input v-model="form1.unit" disabled />
      </el-form-item>
      <el-form-item label="精度" prop="precisions">
        <el-input v-model="form1.precisions" disabled />
      </el-form-item> -->
          <el-form-item label="通讯地址" class="long-input" prop="serialNumber">
            <el-input v-model="form1.serialNumber" disabled />
          </el-form-item>

          <el-form-item label="安装时间" class="long-input" prop="installTime">
            <el-input v-model="form1.installTime" disabled />
          </el-form-item>
          <el-form-item label="坐标" class="long-input" prop="coordinate">
            <slot name="label">
              <el-tooltip  class="tooltip11" effect="dark" content="用于GIS地图测点定位，输入格式为 x坐标,y坐标,z坐标" placement="bottom">
                <div>
                </div>
              </el-tooltip>
            </slot>
            <el-input v-model="form1.coordinate" disabled />
          </el-form-item>
          <el-form-item label="位置分组" class="long-input" prop="siteGroup">
            <el-input v-model="form1.siteGroup" disabled />
          </el-form-item>
        </div>
      </div>

      <!-- <el-form-item label="自选状态" prop="selectedRel">
        <el-input v-model="statusOpitons[form1.selectedRel]" disabled />
      </el-form-item> -->
      <!-- <el-form-item label="衍生指标">
        <el-input v-model="statusOpitons[form1.derive]" disabled />
      </el-form-item> -->
    </el-form>
    <!-- 衍生指标关联计算 -->
    <el-form v-show="form1.derive == 1" class="derive" :model="deriveForm" :inline="true">
      <p>衍生指标关联计算</p>
      <el-form-item label="计算公式">
        <el-input v-model="computeOptions[form2.equation - 1]" disabled />
      </el-form-item>
      <el-form-item label="关联测点一">
        <el-form-item class="hidden-top-border">
          <el-input v-model="form2.firstTargetName" disabled />
        </el-form-item>
      </el-form-item>
      <el-form-item label="关联测点二">
        <el-form-item class="hidden-top-border">
          <el-input v-model="form2.secondTargetName" disabled />
        </el-form-item>
      </el-form-item>
      <el-form-item label="关联测点三">
        <el-form-item class="hidden-top-border">
          <el-input v-model="form2.thirdTargetName" disabled />
        </el-form-item>
      </el-form-item>
    </el-form>

    <div class="btns box around">
      <div style="width: 100px" class="btnSelect1 pointer" @click="$router.go(-1)">返回</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      targetId: "",
      // xyz列表
      xyzList: [
        { id: "1", name: "X" },
        { id: "2", name: "Y" },
        { id: "3", name: "Z" },
      ],
      sysList: [],

      projectId: "",
      patchMethod: "",
      flag: 0,
      form1: { selectedRel: 0, derive: 0, staticDynamicSelected: 1 },
      deriveForm: {},
      trendForm: { trendType: 0 },
      form2: { patchSelected: 0 },
      form3: { outlierSelected: 0, baselineSelected: 0, staticDynamicSeparationSelected: 0 },
      form7: {},
      form8: { tempEffectDuration: 1 },
      form7: { frequencyDuration: 99 },
      form10: { duration: 0 },
      formData: { modalSelected: 0 },
      pointOptions: [],
      point1Options: [],
      point2Options: [],
      point3Options: [],
      typeOptions: [],
      targetOptions: [],
      statusOpitons: ["否", "是"],
      trendOpitons: ["不分析", "多项式", "指数"],
      dataTypeOpitons: ["静态效应", "动态效应", "振动响应"],
      computeOptions: ["挠度修正", "应变花(45°)"],
      preMethodOptions: ["previous", "next", "linear", "pchip", "spline", "pchip", "makima", "movmean", "movmedian"],
      methodOptions: ["矩形窗", "低通滤波", "陷波滤波"],
      timeOptions: ["其他", "1分钟", "5分钟", "10分钟", "15分钟", "20分钟", "0.5小时", "1小时", "1.5小时", "不分析"],
      durationOptions: ["不预警", "1分钟", "5分钟", "10分钟", "15分钟", "20分钟", "1小时", "1天", "1月"],
      timeOptions2: ["3小时", "6小时", "12小时", "不分析"],
      durationOptions: ["不预警", "预警"],
    };
  },
  mounted() {
    this.targetId = this.$route.params.id;
    this.projectId = Number(this.$store.state.projectId);
    this.getForm1Data();
    let arr = this.$store.state.breadcrumbList;
    if (arr.length < 4) {
      arr.push({ name: "查看测点" });
      this.$store.commit("getBreadcrumb", arr);
    }
  },
  beforeDestroy() {
    let arr = this.$store.state.breadcrumbList;
    console.log("测点面包屑列标111", arr);

    arr.splice(3);
    this.$store.commit("getBreadcrumb", arr);
    console.log("测点面包屑列标", arr);
  },
  methods: {
    selectSys() {},
    getForm1Data() {
      this.$axios.get(`${this.baseURL}base/baseTarget/${this.targetId}`).then((res) => {
        res = res.data;
        console.log("获取数据", res);

        if (res.errCode == 200) {
          this.form1 = res.data;
          if (this.form1.typeId == "1008") {
            if (this.form1.typeId == "1008") {
              //  this.disabled1 = false;
              this.form1.typeRel1 = [];
              this.form1.typeRel1 = res.data.typeRel.split(","); //
            }
          }
          this.$forceUpdate();
          if (this.form1.derive == 1) this.getForm2Data();
          
          this.form1.frequency = this.form1.frequency+"秒"
          if(!this.form1.systemName){
            this.form1.systemName = "全部"
          }
        }
      });
      // 获取数据预处理设置、数据二次处理
      this.$axios.get(`${this.baseURL}setttings/settingsDataClean/option/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.form3 = res.data;
          this.form3.selectedArr = [];
          res.data.denoisingBoxSelected == 1 ? this.form3.selectedArr.push(this.methodOptions[0]) : "";
          res.data.denoisingLowpassSelected == 1 ? this.form3.selectedArr.push(this.methodOptions[1]) : "";
          res.data.denoisingNotchSelected == 1 ? this.form3.selectedArr.push(this.methodOptions[2]) : "";
        }
      });
      // 获取数据分析处理设置
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/option/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) this.form7 = this.form8 = this.trendForm = res.data;
      });
      // 获取单指标阈值处理设置
      this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/option/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) this.form10 = res.data;
      });
    },
    // 获取衍生指标
    getForm2Data() {
      this.$axios.get(`${this.baseURL}setttings/settingsDataDerivation/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) this.form2 = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  // border: 1px solid rgba(151, 151, 151, 0.29) !important;
}

.derive,
.multi-form {
  &::v-deep .el-form-item__error {
    top: 38px;
  }
}

.trendForm::v-deep {
  .el-form-item {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1750px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      // width: 188px;
    }

    .el-form-item__label {
      width: 152px;
    }

    .multi-form .el-form {
      padding: 0 1% 0 6.47%;
    }

    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }

      .interval {
        margin-left: 100px;
      }

      .el-form-item__label {
        width: 100px;
      }
    }

    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }

      .el-form-item:not(:first-child) {
        .el-form-item__label {
          width: 70px;
        }
      }
    }

    .limit-value {
      .el-form-item {
        width: 258px;

        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }

    .el-form:first-child {
      .el-form-item__label {
        min-width: 122px;
        white-space: nowrap;
      }
    }

    .derive {
      .el-form-item__label {
        min-width: 110px;
        white-space: nowrap;
      }

      .el-input,
      .el-input__inner {
        width: 183px;
      }
    }

    .multi-form .el-form {
      padding-left: 13px;
    }

    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }

      .el-form-item:nth-child(2) {
        margin-left: 15px;
      }

      .interval {
        margin-left: 80px;
      }
    }

    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label {
          width: 72px;
        }
      }

      .el-form-item {
        margin-right: 20px !important;
      }
    }

    .limit-value {
      .el-form-item {
        width: 220px;

        .el-form-item__label {
          margin-right: 10px !important;
        }

        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }

      .el-form-item:nth-child(odd) {
        width: 220px;

        .el-form-item__label {
          width: 86px !important;
        }
      }
    }
  }
}

.time_length::v-deep .el-form-item__label {
  margin-left: 40px;
}

.el-form::v-deep {
  .el-form-item__label {
    padding: 0;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background: #0c265a;
  }

  .red-warning .el-form-item__label {
    text-align: right;
  }

  .el-form-item__content {
    height: 52px;
    width: auto;
    line-height: 52px;
    text-align: center;
  }

  .el-input__inner {
    padding-left: 30px;
    height: 30px;
    line-height: 30px;
    // text-align: center;
  }
  .install-time-warp {
    .el-input__inner {
      padding-left: 30px;
      height: 30px;
      line-height: 30px;
      // text-align: center;
    }
  }

  .el-select {
    .el-input__inner {
      text-align: left;
      padding-left: 13px;
      border: 1px solid rgba(151, 151, 151, 0.29);
    }

    .el-input__icon {
      height: 30px;
      line-height: 30px;
    }
  }

  .el-input.is-disabled .el-input__inner {
    border: 1px solid #383e41 !important;
    // background: rgba(56, 63, 65, 0.29) !important;
    border-radius: 0 !important;
    border: none !important;
  }

  .el-form-item.long-input {
    width: 50% !important;

    .el-input,
    .el-input__inner {
      // width: 95% !important;
    }
  }
}

p {
  color: #fff;
  line-height: 40px;
  text-align: center;
}

.el-form-item {
  margin: 0;
  border-top: 1px solid #2c3134;
}

.el-form-item {
  display: inline-flex;

  &::v-deep {
    .el-form-item__content {
      flex: 1;
    }
  }
}

.title {
  // width: 152px;
  display: flex;
  word-wrap: none;
  white-space: nowrap;
  color: #fff;
  align-items: center;
  justify-content: center;
  // background: #0c265a;
}

.max_factorial {
  margin-left: 20px;
}

.denosing-form .title {
  border-bottom: 1px solid #2c3134;
}

.derive {
  &::v-deep {
    .el-form-item__label,
    .el-col {
      margin-right: 22px;
    }
  }
}

.multi-form,
.el-form:first-of-type,
.el-form:nth-of-type(2) {
  margin-top: 20px;
  // border-radius: 8px;
  margin-bottom: 26px;
  // border: 1px solid #5E5E5F;
  // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  background: #05193f;
}

.el-form:first-child {
  &::v-deep {
    .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding-left: 25px;
        border: none;
      }
    }
  }

  .el-form-item {
    width: 50%; //25
  }

  .el-form-item:nth-of-type(5) {
    //width: 50%;
  }

  .el-form-item:nth-of-type(6) {
    //width: 50%;
    //padding-right: 42px;
  }

  .el-form-item:nth-last-of-type(1) {
    width: 50%;
  }
}

.el-form:nth-child(2) {
  .el-form-item {
    display: block;

    &::v-deep {
      .el-form-item__content {
        display: inline-flex;
      }
    }

    .el-select:not(:last-child) {
      margin-right: 22px;
    }
  }
}

.multi-form {
  section {
    display: flex;
    border-top: 1px solid #2c3134;

    p {
      width: 152px;
      height: 52px;
      line-height: 52px;
      background: #0c265a;
    }

    .el-form {
      flex: 1;
      margin: 0;
      border: none;
      display: flex;
      flex-wrap: wrap;
      box-shadow: none;

      .el-form-item {
        border: none;
        height: 52px;

        &::v-deep {
          .el-form-item__label {
            text-align: left;
            margin-right: 20px;
            white-space: nowrap;
            background: transparent;
          }
        }

        .unit {
          padding: 0 12px;
          white-space: nowrap;
        }
      }
    }
  }

  .denosing-form {
    .el-form {
      margin: 0;
      border-radius: 0;
      border-bottom: 1px solid #2c3134;
    }

    .el-form-item:nth-of-type(2) {
      width: 50%;

      &::v-deep {
        .el-form-item__content {
          white-space: nowrap;
        }
      }
    }

    .el-form-item {
      &::v-deep .el-form-item__error {
        top: 75% !important;
      }
    }
  }

  .rehandle {
    .el-form-item:first-child {
      &::v-deep .el-form-item__label {
        width: 182px;
      }
    }
  }
}

.multi-form:nth-of-type(-n + 1) {
  .el-form-item {
    margin-right: 4%;
  }
}

.set-time {
  padding: 0 2% !important;

  .el-form-item:first-child {
    margin-right: 20px;
  }
}

.set-threshold {
  .title {
    border: none;
  }

  .form-title {
    margin: 20px 0;

    span {
      width: 50%;
      display: inline-block;
    }
  }

  .form-list {
    flex: 1;
    padding: 0 0 23px 2%;

    .el-form {
      padding: 0;

      &::v-deep {
        .el-form-item__content {
          width: 78px;
        }

        .el-form-item__label {
          padding: 0;
          width: 90px;
        }
      }
    }

    .limit-value {
      width: 50%;

      .el-form-item {
        display: inline-flex;
      }
    }
  }
}

.btns {
  margin-top: 44px;
  text-align: center;

  .el-button {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .el-button:last-child {
    margin-left: 50px;
    background: #1e2529;
  }
}

::v-deep .el-form {
  .el-upload-list--picture-card .el-upload-list__item-status-label {
    background: #2772f0;
  }

  .formMain {
    // border: 1px solid #5E5E5F;
    // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
    background: #05193f;
    border: 1px solid #0c3380;
    .title {
      color: #fff;
      line-height: 40px;
      text-align: center;
    }

    .el-form-item {
      width: 50%;
      min-height: 52px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      border-top: 1px solid rgba(59, 104, 169, 0.48);
      border-right: 1px solid rgba(59, 104, 169, 0.48);
      &.borderbot {
        border-bottom: 1px solid #5e5e5f;
      }

      &.cityItem {
        .el-form-item__content {
          .el-select {
            height: 31px;
            width: 40%;

            .el-input {
              width: 100%;
              border-radius: 4px;
              // border: 1px solid rgba(151, 151, 151, 0.29);
              line-height: 31px;
            }
          }
        }
      }

      &.docItemMain {
        min-height: 108px;
        position: relative;
        width: 100%;

        .el-form-item {
          border: none;
        }

        .el-form-item__label {
          height: 100%;
          position: absolute;
          min-width: 122px;
        }

        .el-form-item__content {
          line-height: 0;
          // padding-left: 216px;

          & > div {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: 100%;
          }

          .el-upload-list--picture-card {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;

            .el-upload-list__item {
              width: 80px;
              height: 60px;
              margin: 20px 0 20px 40px;
              border: none;
            }
          }

          .el-upload--picture-card {
            width: 80px;
            height: 60px;
            // background: #21272b;
            background: #0a1f4a;

            border-radius: 2px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 40px;

            i {
              color: #3d6391;
            }
          }

          div.hideUpload {
            .el-upload--picture-card {
              display: none;
            }
          }
        }
      }

      .el-form-item__label {
        // width: 216px;
        height: 100%;
        background: #0c265a;
        // border-radius: 0px 0px 0px 8px;
        padding: 0;
        text-align: center;
        line-height: 52px;

        &.requiredTip:before {
          content: "*";
          color: #f56c6c;
          margin-right: 4px;
        }
      }

      .el-form-item__content {
        flex: 1;
        display: flex;
        align-items: center;
        // justify-content: center;

        .el-select {
          .el-input {
            width: 178px;
            height: 31px;
            border-radius: 4px;
            // border: 1px solid rgba(151, 151, 151, 0.29);
            line-height: 31px;
          }
        }

        .el-input {
          // width: 100%;
          height: 52px;
          width: 260px;
          border: none;

          .el-input__inner {
            border: none;
            // text-align: center;
          }

          .el-input__icon {
            line-height: 32px;
          }
        }

        .el-checkbox__inner {
          width: 20px;
          height: 20px;

          &:after {
            height: 9px;
            left: 6px;
            width: 5px;
          }
        }

        .el-form-item__error {
          left: 48px;
          text-align: right;
          margin-left: 300px;
        }

        .tips {
          color: #f0f0f0;
          padding-left: 30px;
        }
      }
    }
  }
}

::v-deep .long-input1 .el-input,
::v-deep .long-input1 .el-input .el-input__inner {
  width: 100% !important;
}

.formMain-title {
  display: flex;
  align-items: center;
  padding: 16px 0 23px 16px;
}
.formMain-content {
  margin: 0 30px 20px 43px;
  border-left: 1px solid rgba(59, 104, 169, 0.48);
  border-bottom: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .el-form-item__error {
  right: 5px !important;
  top: auto;
  left: auto;
}
::v-deep .el-form-item__label {
  // border-top: 1px solid rgba(59, 104, 169, 0.48);
  border-right: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .el-form-item__content {
  background-color: #0a1a42;
  // border-top: 1px solid rgba(59, 104, 169, 0.48);
  // border-right: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .selectItem .el-input .el-input__inner {
  background: #081b42 !important;
}
::v-deep .el-input .el-input__inner {
  background-color: #0a1a42 !important;
}

::v-deep .jungongTime .el-input__prefix {
  left: auto;
  right: 20px;
}
::v-deep .jungongTime .el-input__inner {
  padding-left: 15px;
}

::v-deep .el-form-item__content .el-cascader {
  width: 262px;
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  padding-left: 13px;
}
::v-deep .el-form-item__content .el-cascader .el-input {
  border: 1px solid rgba(151, 151, 151, 0.29) !important;
  height: 32px !important;
  line-height: 32px;
  position: relative;
}
::v-deep .el-form-item__content .el-cascader .el-cascader__tags {
  width: 200px;
  left: 15px;
}
::v-deep .el-form-item__content .el-cascader .el-input .el-input__inner {
  height: 28px !important;
}

::v-deep .border-item {
  border-bottom: 1px solid rgba(59, 104, 169, 0.48);
  position: relative;
  top: 1px;
}

::v-deep .img-box .el-form-item {
  width: 248px !important;
  display: inline-block !important;
  .upload-demo {
    margin: 0;
    padding-left: 28px;
  }
}
::v-deep .img-box .el-form-item__error {
  width: 102px !important;
  position: absolute;
  right: 0px !important;
  display: flex;
  justify-content: flex-end;
}

::v-deep .danwei-item {
  position: relative;
}
::v-deep .danwei-item .el-form-item {
  width: 100% !important;
}

::v-deep .danwei-item .el-form-item__content {
  width: 100%;
}

::v-deep .danwei-item .el-form-item__error {
  position: absolute;
  right: 5px !important;
}
::v-deep .system-item .el-form-item__content {
  width: 200px !important;
}
::v-deep .system-item .model_upload {
  width: 200px !important;
}
::v-deep .el-form .formMain .el-form-item .el-form-item__content .el-select .el-input {
  width: 250px;
  height: 32px;
  border-radius: 0px;
  border: 1px solid rgba(151, 151, 151, 0.29);
  line-height: 28px;
  margin-left: 13px;
}
::v-deep .anzhuang-item .el-input__prefix {
  left: auto;
  display: none;
}
::v-deep .anzhuang-item .el-form-item__content {
  // border-bottom: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep .img-item {
  width: calc(100% - 154px) !important;
  position: relative;
  left: 152px;
  background: #05193f !important;
}

::v-deep .img-item .el-form-item {
  width: 200px !important;
}
::v-deep .img-item .el-upload-list {
  display: flex;
  width: 500px !important;
}
// el-upload-list
::v-deep .img-item .el-form-item__content {
  background: #05193f !important;
  position: relative;
  left: 2px;
}
.formMain-title {
  display: flex;
  align-items: center;
  padding: 16px 0 23px 16px;
}
.formMain-content {
  margin: 0 30px 20px 43px;
  border-left: 1px solid rgba(59, 104, 169, 0.48);
  border-bottom: 1px solid rgba(59, 104, 169, 0.48);
}
::v-deep  .el-form-item__content .el-input{
margin-left: 20px;
}
::v-deep  .el-input.is-disabled .el-input__inner{
  background: #0a1a42 !important;
}
.tooltip11{
    position: absolute;
    left: -30px;
    width: 14px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../../../assets/images/tipoff.png');
    background-size: 100% 100%;
    &:hover{
      background-image: url('../../../assets/images/tipon.png');
    }
  }
</style>
